const DataServies = (() => { 

var AIQuestion;
var applicationCardsData;
var careersValues
var expertiseProducts;
var jobOpenings;
var ourProcess;
var partners;
var productsSectionData
var socialIcons;
var modelStaticData;
var WeaponDetetectionInput;

AIQuestion = [
  {
    question: "How can i integrate this?",
    answer: "Magure AI provides cloud based API and python SDK. You can easily signup get a key and start using it. If you would like to deploy the platform in your own cloud infrastructure we can also provide you with a license. Contat us to know more."
  },

  {
    question: "How is the cost calculated?",
    answer: "If you want to use our cloud based API, the prices simply depend on the usage. For SDKs and in premise cloud deployment our sales team can guide you and provide you with the most affordable pricing."
  },
  {
    question: "What is the minimum hardware required to run these models?",
    answer: "It can run on light weight devices and supports edge deployments. To get more details please reach out to us."
  },
  {
    question: "Can it process camera feeds?",
    answer: "Yes! you can connect with different camera feeds."
  },
  {
    question: "What is the accuracy of the model?",
    answer: "These models persists very high accuracy, but in real world scenario there can be some edge cases. We can always train the models for better accuracies. Models are learning with us. :)"
  },
];

applicationCardsData = [
  {
    id:"1",
    title: "Card Title",
    description: "Detect all unique faces in an image or video.",
    route: ""  },
  {
    id:"2",
    title: "Card Title",
    description: "Compare, match and search faces using an unique face embeddings.",
    route: ""  },
  {
    id:"3",
    title: "Card Title",
    description: "Detect if face in an image is actual or spoofed.",
    route: ""  },
  {
    id:"4",
    title: "Card Title",
    description: "Detect emotions, if person is Happy, Sad, Angry, Excited, or Suprised.",
    route: ""  },
  {
    id:"5",
    title: "Card Title",
    description: "Determine gender and age and get high level insights about the audience.",
    route: ""  },
];

  careersValues = [
    // {
    //   id: 1,
    //   title: "Agile and Dynamic",
    //   text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    // },
    {
      id: 4,
      title: "Harness the power of yourself",
      text: "We are a people centric company, and as we grow, we want each and everyone working with us should grow.",
    },
    {
      id: 5,
      title: "Secure Borderless Workspace",
      text: "Magure has people working globally from different parts of the world. Experience a world class team.",
    },
    {
      id: 2,
      title: "Happiness Matters",
      text: "Do what makes you happy. We have no bonds and easy hiring system. Work with us till you are happy.",
    },
    {
      id: 3,
      title: "Make an Impact",
      text: "With Magure you will get to work with enterprises touching millions of life. Your code will add convience to life of millions of people.",
    }
    ];

    jobOpenings = [
      {
        id: 1,
        title: "Project Manager",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      },
      {
        id: 2,
        title: "Data Analyst",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi, perspiciatis Lorem ipsum dolor sit amet..",
      },
      {
        id: 3,
        title: "Solutions Engineer",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi, perspiciatis Lorem ipsum dolor sit amet..",
      },
      {
        id: 4,
        title: "Trainee",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi, perspiciatis Lorem ipsum dolor sit amet..",
      }
      ];

  socialIcons = [
    {
      id: "1",
      title: "facebook",
      image: "facebook.png",
      url: "https://www.facebook.com/magureinc"
    },
    {
      id: "2",
      title: "twitter",
      image: "twitter.png",
      url: "https://twitter.com/Magureinc"
    },
    {
      id: "3",
      title: "linkdin",
      image: "linkdin.svg",
      url: "https://www.linkedin.com/company/maguresoftwares/"
    },
    {
        id: "4",
        title: "magure",
        image: "medium.svg",
        url: "https://medium.com/magure"
    },
    {
        id: "4",
        title: "youtube",
        image: "youtube.svg",
        url: "https://www.youtube.com/channel/UCgD7g8gfUPNR1IB7msui_Bw"
    },
  ];

  ourProcess = [
    {
      id: "1",
      title: "Client Centric",
      text: "Client-centricity is our way of doing business, which helps us deliver satisfaction and leads to more referrals. ",
    },
    {
      id: "2",
      title: "Agile",
      text: "Changes are inevitable. We understand when product needs some pivoting. We support adaptive planning, evolutionary development, early delivery and continual imporvement. Completely flexible to changes.",
    },
    {
      id: "3",
      title: "Responsible",
      text: "Being responsible is one of the important key to establish trust. We are responsible for our success and failures. The choices we make are ultimately our responsibility.",
    },
    {
      id: "4",
      title: "Technology Enthusiasts",
      text: "Technology is ever evolving, we at Magure always keep on exploring latest cutting edge technology. We organise meetups and try to connect, collaborate and contribute.",
    }
    ];
  
  partners = [
    {
      id:"1",
      image:"Huawei.png"
    },
    {
      id:"14",
      image:"mitsumi-distribution-black-white.png"
    },
    {
      id:"2",
      image:"Fourc.png"
    },
    {
      id:"16",
      image:"Verizon.webp"
    },
    {
      id:"14",
      image:"Nutrifit.webp"
    },
    {
      id:"13",
      image:"Mokriya.webp"
    },
    {
      id:"3",
      image:"Codejika.webp"
    },
    {
      id:"11",
      image:"LotusLbs.webp"
    },
    {
      id:"9",
      image:"IVY.webp"
    },
    {
      id:"10",
      image:"Jocata.webp"
    },
    {
      id:"17",
      image:"m56_logo.webp"
    },
    // {
    //   id:"6",
    //   image:"Hockystck.webp"
    // },
    {
      id:"12",
      image:"Mastermind.webp"
    },
    // {
    //   id:"15",
    //   image:"Starshine.webp"
    // },
    // {
    //   id:"1",
    //   image:"bethere.webp"
    // },
    // {
    //   id:"2",
    //   image:"BigPesa-logo.webp"
    // },
    
    // {
    //   id:"4",
    //   image:"Dynemech.webp"
    // },
    // {
    //   id:"5",
    //   image:"fixer.webp"
    // },
    // {
    //   id:"7",
    //   image:"incubators.webp"
    // },
    // {
    //   id:"8",
    //   image:"Instalinks.webp"
    // }
  ];

  productsSectionData = [
    {
      id: 1,
      title:"Magure AI Platform",
      descriptionOne: "Magure AI playform consists of almost 16 models which can be accessed through our SDK or APIs. We also support deployment to client cloud infrastructure.",
      descriptionTwo: "Gone are those days when we thought Machine Learning applications are far from being in the mainstream. A lot of companies, PSUs, airports, banks are already using Machine Learning to automate and improve there existing processes. We at Magure want to accelerate the adoption of Machine Learning applications and have recently deployed a few of our models in actual production sites. We are ready to collaborate and explore the use cases.",
      image: "FaceDetection.png",
      imageTitle: "Magure AI",
      isVideo: true,
      videoLink: "https://www.youtube.com/embed/e2u7BzSbXuI",
      imageText: "Magure AI platform supports deployment in edge platforms like Raspberry Pi, Google Coral and any other infrastructure.",
      productDetails: [
        {
          id:"1",
          title: "Face Detection",
          description: "Detect all unique faces in an image or video.",
          route: "/face-detection"
        },
        {
          id:"2",
          title: "Face Comparision",
          description: "Compare, match and search faces using an unique face embedding.",
          route: "/face-compare"
        },
        {
          id:"3",
          title: "Anti-Spoofing Detection",
          description: "Detect if face in an image is actual or spoofed.",
          route: "/face-spoofing-detection"
        },
        {
          id:"4",
          title: "Emotion Detection",
          description: "Detect emotions, if person is Happy, Sad, Angry, Excited, or Suprised.",
          route: "/emotion-detection"
        },
        {
          id:"5",
          title: "Gender and Age Predictions",
          description: "Determine gender and age and get high level insights about the audience.",
          route: "/age-prediction"
        },
        // {
        //   id:"6",
        //   title: "Face Embedding Search",
        //   description: "Search face from existing database or create your own custom database. Cluster all face embeddings.",
        //   route: "/face-detection"
        // },
        {
          id:"7",
          title: "Object Detection",
          description: "Detect objects present in an image or video. Can train for custom objects",
          route: "/object-detection"
        },
        // {
        //   id:"8",
        //   title: "Pose Estimation",
        //   description: "Detect yoga poses, fall detection, wall climbs in highly secure area.",
        //   route: "/face-detection"
        // },
        // {
        //   id:"10",
        //   title: "Image Search",
        //   description: "Search for similar images using our image search model.",
        //   route: "/face-detection"
        // },
        // {
        //   id:"11",
        //   title: "Yoga Pose Detection",
        //   description: "Detect yoga poses and correct the pose.",
        //   route: "/face-detection"
        // },
        // {
        //   id:"12",
        //   title: "Wheelchair Detection",
        //   description: "Detect wheelchairs and provide appropriate assistance.",
        //   route: "/face-detection"
        // },
          {
            id:"13",
            title: "Celebrity-Recognition",
            description: "Identify celebrity present in an image or video",
            route: "/celebrity-recognition"
          },
          {
            id:"14",
            title: "Weapon Detection",
            description: "Identifies weapons present in an image or video",
            route: "/weapon-detection"
          },
          {
            id:"15",
            title: "Wheelchair Detection",
            description: "Identifies wheelchair present in an image or video",
            route: "/wheelchair-detection"
          },
          {
            id:"16",
            title: "Chat GPT-3",
            description: "Get answers to questions in corresponds to a given context or paragraph.",
            route: "/question-answering"
          },
      ]
    },
    {
      id: 2,
      title:"Easy Data",
      descriptionOne: "Make informed decisions, convert your questions into database queries. This cutting edge platform uses Augmented Analytics concepts and help organisations dig data easily.",
      descriptionTwo: "With the help of our latest innovation which is powered by Machine Learning and NLP (Natural language processing) approach, we have built state of art system which can convert your questions to database query. Experience true power and get data in your fingertips. Contact us to know more.",
      image: "FaceComparison.webp",
      imageTitle: "Make Data Digging Easy",
      videoLink: "",
      isVideo: false,
      imageText: "Ask questions to your database and get answers within few seconds.",
      productDetails: []
    },
  ];

  expertiseProducts = [
    {
      id: "1",
      title: "Technology Consultation",
      icon: "gear.svg",
      text: "Have some product ideas? Need a team who can help you make your idea a reality? Or need help with App Strategy and Design? ",
    },
    {
      id: "2",
      title: "Product Engineering",
      icon: "pen.svg",
      text: "Our offerings for product engineering include consulting activity, designing and development of products using various hardware, embedded, software and IoT solutions.",
    },
    {
      id: "3",
      title: "Edge Computing",
      icon: "computing.svg",
      text: "Looking to automate few of your operations using edge computers, we have team of experts who has done splendid work. Want to learn about our work.",
    },
    {
      id: "4",
      title: "Custom ML/AI model",
      icon: "ml.svg",
      text: "If you are looking for a ML/AI model which can help you take faster decisions and automate your workflows. We have a team of data scientists and machine learning engineers to cater your need.",
    },
    {
      id: "5",
      title: "Blockchain Applications",
      icon: "blockChain.svg",
      text: "Team here has worked on Ethereum, Iota, Hyperledger and adding new skills, if you are looking for blockchain enthusiasts - you are in the right place.",
    },
    {
      id: "6",
      icon: "product.svg",
      title: "Product Modernization",
      text: "Have an existing product and want to add more features? You have come to right place. We have lot of companies who came with existing products and problems and now they are permanent partners of Magure.",
    },
    {
      id: "7",
      icon: "deployment.svg",
      title: "Automating Deployment",
      text: "Making deployments easy and robust is dream of every company, no one wants to stay late and work on deployment bugs. Magure has team of experienced professionals who have worked on Microsoft Azure, Docker, AWS, GCP, DO, Ansible, Kubernetes etc.",
    },
    {
      id: "8",
      icon: "quantum.svg",
      title: "Quantum Computing",
      text: "Our team is working and experimenting with Quantum computers, we would love to hear and understand what you would like to do with Quantum computers. Follow us on github to see latest experiments we have done on Quantum computers.",
    },

    ];

  modelStaticData = {
    AgeGenderSectionInput: {
      title: "Age Gender Prediction",
      description: "Determine the age and gender of a face detected in an image or video. It used to perform high level of analytics and can automatically tell the gender ratio and interested age groups visiting a place. Check the API response to see the result. Tap on Submit to try now!",
      imgURL: "https://pbs.twimg.com/profile_images/696736559140179968/dDuFF5B__400x400.jpg",
      isResult: false,
      isLocalInput: true,
      sampleImageOne: "https://pbs.twimg.com/profile_images/696736559140179968/dDuFF5B__400x400.jpg",
      sampleImageTwo: "https://live.staticflickr.com/8158/7571852962_6780b90342_b.jpg"
    },
    CelebritySectionInput: {
      title: "Celebrity Recognition",
      description: "This endpoint search for the celebrity in the image from the database we have created. It returns the matching celebrity name, wikipedia url and image url present in our database. Tap on Submit to try now!",
      imgURL: "https://pbs.twimg.com/profile_images/696736559140179968/dDuFF5B__400x400.jpg",
      isResult: false,
      isLocalInput: true,
      sampleImageOne: "https://pbs.twimg.com/profile_images/696736559140179968/dDuFF5B__400x400.jpg",
      sampleImageTwo: "https://static.toiimg.com/thumb/msid-70305128,imgsize-121900,width-800,height-600,resizemode-75/70305128.jpg"
    },
    FaceSpoofingDetectionSectionInput: {
      title: "Face Spoofing Detection",
      description: "Detect if face present in the image is actual or spoofed. It is used in high secure areas where access is granted based on facial biometrics. This model uses different parameters to determine if face is spoofed. Tap on Submit to try now!",
      imgURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmKBw0xJRa3inIkwxJwO8vHJ4mtv8WWOyLRXtDvG48z9RLZH_zRS3py985h_kVTtWAdX4&usqp=CAU",
      isResult: false,
      isLocalInput: true,
      sampleImageOne: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmKBw0xJRa3inIkwxJwO8vHJ4mtv8WWOyLRXtDvG48z9RLZH_zRS3py985h_kVTtWAdX4&usqp=CAU",
      sampleImageTwo: "https://image.shutterstock.com/image-photo/close-portrait-smiling-handsome-man-260nw-1011569245.jpg"
    },
    ObjectDetectionSectionInput: {
      title: "Object Detection",
      description: "Detect various objects present in an image or video. Draw precised bounding box around the object. This model can be trained with different custom objects. Tap on Submit to try now!",
      imgURL: "https://cdn.britannica.com/77/170477-050-1C747EE3/Laptop-computer.jpg",
      isResult: false,
      isLocalInput: true,
      sampleImageOne: "https://cdn.britannica.com/77/170477-050-1C747EE3/Laptop-computer.jpg",
      sampleImageTwo: "https://media.wired.com/photos/5d09594a62bcb0c9752779d9/1:1/w_1500,h_1500,c_limit/Transpo_G70_TA-518126.jpg"
    },
    EmotionDetectionSectionInput: {
      title: "Emotion Detection",
      description: "Analyze and identify the facial emotion. This model provides the confidence for each emotion. It has various applications in getting feedback in resturants, determine happiness index of employees in office or analysing the emotions during interview. Tap on Submit to try now!",
      imgURL: "https://image.shutterstock.com/image-photo/close-portrait-smiling-handsome-man-260nw-1011569245.jpg",
      isResult: false,
      isLocalInput: true,
      sampleImageOne: "https://image.shutterstock.com/image-photo/close-portrait-smiling-handsome-man-260nw-1011569245.jpg",
      sampleImageTwo: "https://cdn.mos.cms.futurecdn.net/DMUbjq2UjJcG3umGv3Qjjd.jpeg"
    },
    FaceDetetectionInput: {
      title: "Face Detection",
      description: "Detect human faces within an image and draw high precision bounding box around the face. It is fast and accurate and can run on cloud or edge devices. Tap on Submit to try now!",
      imgURL: "https://media.istockphoto.com/photos/weve-only-ever-achieved-great-things-together-picture-id1035530956?k=6&m=1035530956&s=612x612&w=0&h=tmYLbVdZGs6BA1gTByTe8qOLmL5OCvegtt0gL4pUBeo=",
      isResult: false,
      isLocalInput: true,
      sampleImageOne: "https://media.istockphoto.com/photos/weve-only-ever-achieved-great-things-together-picture-id1035530956?k=6&m=1035530956&s=612x612&w=0&h=tmYLbVdZGs6BA1gTByTe8qOLmL5OCvegtt0gL4pUBeo=",
      sampleImageTwo: "https://upload.wikimedia.org/wikipedia/commons/b/bb/Dangiuz_Face.jpg"
    },

    FCsourceImageInput: {
      title: "Source image",
      imgURL: "https://bakersfieldnow.com/resources/media2/16x9/full/1015/center/80/8c5cb271-468e-4d07-af8c-cf15918caaf0-large16x9_BritainCaptainAmeri_Smit1.jpg",
      isResult: false,
      isLocalInput: true,
      sampleImageOne: "https://bakersfieldnow.com/resources/media2/16x9/full/1015/center/80/8c5cb271-468e-4d07-af8c-cf15918caaf0-large16x9_BritainCaptainAmeri_Smit1.jpg",
      sampleImageTwo: "https://cdn1.edgedatg.com/aws/v2/abc/ABCUpdates/blog/2423610/62b850ffa5b69e39b9d14e63c7d5c7d1/512x288-Q90_62b850ffa5b69e39b9d14e63c7d5c7d1.jpg"
    },
    FCtargetImageInput: {
      title: "Target image",
      imgURL: "https://live.staticflickr.com/8158/7571852962_6780b90342_b.jpg",
      isResult: false,
      isLocalInput: true,
      sampleImageOne: "https://live.staticflickr.com/8158/7571852962_6780b90342_b.jpg",
      sampleImageTwo: "https://upload.wikimedia.org/wikipedia/commons/3/33/Mark_Kassen%2C_Tony_C%C3%A1rdenas_and_Chris_Evans_%28cropped%29.jpg"
    },
    WeaponDetetectionInput: {
      title: "Weapon Detection",
      description: "Detect weapons within an image and draw high precision bounding box around the weapon. It is fast and accurate and can run on cloud or edge devices. Tap on Submit to try now!",
      imgURL: "https://c8.alamy.com/comp/RPTJWM/middle-age-senior-hoary-criminal-man-holding-gun-weapon-over-isolated-background-with-a-confident-expression-on-smart-face-thinking-serious-RPTJWM.jpg",
      isResult: false,
      isLocalInput: true,
      sampleImageOne: "https://c8.alamy.com/comp/RPTJWM/middle-age-senior-hoary-criminal-man-holding-gun-weapon-over-isolated-background-with-a-confident-expression-on-smart-face-thinking-serious-RPTJWM.jpg",
      sampleImageTwo: "https://i.pinimg.com/originals/98/a2/eb/98a2ebd38731ae010b52068ab1d4eb57.png"
    },
    WheelChairDetetectionInput: {
      title: "Wheelchair Detection",
      description: "Detect wheelchair within an image and draw high precision bounding box around the wheelchair. It is fast and accurate and can run on cloud or edge devices. Tap on Submit to try now!",
      imgURL: "https://c8.alamy.com/comp/X10438/two-disabled-men-in-wheelchairs-in-a-park-X10438.jpg",
      isResult: false,
      isLocalInput: true,
      sampleImageOne: "https://c8.alamy.com/comp/X10438/two-disabled-men-in-wheelchairs-in-a-park-X10438.jpg",
      sampleImageTwo: "https://www.braceworks.ca/wp-content/uploads/2018/07/Abbey-Road-album-cover-scope.jpg"
    },
  };

  return {
    AIQuestion,
    applicationCardsData,
    careersValues,
    expertiseProducts,
    jobOpenings,
    socialIcons,
    ourProcess,
    partners,
    productsSectionData,
    modelStaticData,
    WeaponDetetectionInput
  }
})();

export default DataServies;

