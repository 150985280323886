<template>
  <button
    data-aos-once="true"
    class="btn"
    v-bind:class="classObject"
    v-bind:type="typeObject"
    v-on:click="clicked()"
    :style="{borderRadius:isBorderRadiud ? '20px' : 'none'}"
  >
    <router-link to="/contact-us"></router-link>
    <slot></slot>
  </button>
</template>

<script>
import ButtonState from "../lib/button-state";
import ButtonType from "../lib/button-type";

export default {
  computed: {
    classObject() {
      return {
        "btn-primary": this.type === ButtonType.Primary,
        "btn-secondary": this.type === ButtonType.Secondary,

        "is-complete": this.state === ButtonState.Complete,
        "is-loading": this.state === ButtonState.Loading
      };
    },
    typeObject() {
      if (this.isConfirm) {
        return "submit";
      }

      return "button";
    }
  },
  methods: {
    clicked() {
      if (this.href) {
        if (this.$route.path !== this.href) {
          this.$router.push(this.href);
        }
      } else {
        this.$emit("click");
      }
    }
  },
  props: {
    isConfirm: Boolean,
    state: String,
    type: String,
    isBorderRadiud: Boolean,
    href: String
  }
};
</script>

<style>
</style>