<template>
  <a class="icon mr-2 flex justify-center items-center" :href="path" target="blank">
    <img class :src="require('@/assets/images/' + iconName + '')" alt="social-icon" />
  </a>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    iconName: String,
    path: String
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/Variables.scss";
.icon {
  height: 30px;
  width: 30px;
  background: $gray;
  padding: 6.5px;
  border-radius: 50%;

  &:hover {
    background: $dark;
    transition: all 0.3s ease-in-out;
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}
</style>